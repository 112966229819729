import {
  getPaginated,
  PaginatedRequest,
  PaginatedResponse,
} from "@/application/api/getPaginated";
import { AxiosResponse } from "axios";
import mapIndexResponseErrors from "@/application/util/mapIndexResponseErrors";

export interface LogFinIndexItem {
  id: number;
  dimProduct: {
    articleNumber?: string;
    productName?: string;
    revenueCode?: string;
  };
  initialStockMonth: number;
  salesOrders: number;
  received: number;
  returns: number;
  correction: number;
  initialStockNextMonth: number;
}

export const clientLogFinIndex = async (
  data: PaginatedRequest,
  clientId: number
): Promise<AxiosResponse<PaginatedResponse<LogFinIndexItem[]>>> => {
  const response = await getPaginated(`client/${clientId}/log-fin`, data);
  response.data.data = mapIndexResponseErrors(response.data.data);

  return response;
};
