
import Vue, { PropType } from "vue";
import KFieldGroup from "@/components/crud/fields/KFieldGroup.vue";
import KTextField from "@/components/crud/fields/KTextField.vue";
import { mapGetters } from "vuex";
import KSelect from "@/components/crud/fields/KSelect.vue";
import {
  getTranslatedEnumOptions,
  TranslatedEnumOptions,
} from "@/application/util/enum";
import { Month } from "@/modules/logFin/enum/Month";

type ComponentData = {
  internalFilters: Record<string, any>;
  monthOptions: TranslatedEnumOptions;
};

export default Vue.extend({
  name: "StockFlowReportFilter",
  components: {
    KSelect,
    KTextField,
    KFieldGroup,
  },
  props: {
    filters: {
      type: Object as PropType<Record<string, any>>,
      required: true,
    },
  },
  data: (): ComponentData => ({
    internalFilters: {
      month: undefined,
      year: undefined,
    },
    monthOptions: getTranslatedEnumOptions(Month, "stock.months"),
  }),
  computed: {
    ...mapGetters("authorisation", ["client"]),
  },
  watch: {
    filters: {
      handler(newFilters) {
        this.internalFilters = newFilters;
      },
      immediate: true,
      deep: true,
    },
    internalFilters: {
      handler(newFilters) {
        this.$emit("update:filters", newFilters);
      },
      deep: true,
    },
  },
});
